<template>
  <div  class="minWidth1000">
    <custom-actions :panel="false">
      <template #header>
        <el-button @click="$router.push('/system/menu/add')" type="primary">新增菜单</el-button>
      </template>
    </custom-actions>

    <el-card shadow="never" class="mt10">
      <el-table class="mt10" stripe size="mini" border v-loading="loading" :data="tableData" row-key="menuId" :tree-props="{children: 'childNode'}">
        <el-table-column label="菜单名称" prop="menuName"></el-table-column>
        <el-table-column label="菜单地址" prop="url"></el-table-column>
        <el-table-column label="菜单图标" prop="iconPath"></el-table-column>
        <el-table-column label="是否叶子节点" prop="">
          <template v-slot="{ row }">
            {{row.isLeaf === '0' ? '否' : '是'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="">
          <template v-slot="{ row }">
            <el-button type="text" @click="$router.push('/system/menu/edit?id=' + row.menuId)">编辑</el-button>
            <el-button type="text" @click="changeStatus(row.menuId,row.status)">{{row.status === 1?'禁用':row.status === 2?'启用':''}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'MenuManage',
  data () {
    return {
      loading: false,
      tableData: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.system.selectSysMenuByList).then(res => {
        this.tableData = res
      }).finally(() => {
        this.loading = false
      })
    },
    changeStatus (menuId, status) {
      this.loading = true
      this.$axios.post(this.$apis.system.disableSysMenu, { menuId }).then(res => {
        const text = status === 1 ? '禁用成功' : status === 2 ? '启用成功' : ''
        this.$message.success(text)
        this.loadData()
      }).finally(() => {
        this.loading = false
      })
    },
    handleNodeClick () {}
  }
}
</script>

<style scoped lang='scss'>

</style>
